var $ = jQuery.noConflict();
var DFPid = 4848013;
var isMobile = false;



$(document).ready(function(){
  console.log('ready? ok!');
if( $(window).width() < 767){
  isMobile = true;
  console.log("its mobile width");
  console.log(isMobile);

}
check_for_ads_in_view();

  // HEAERMENU OPEN
  $('#ham-button').click(function(){
    console.log('clicky');
    $('#masthead').toggleClass('pull-down');
    $('body').toggleClass('open-menu');
    if(!$('body').hasClass('scrolled')){
      $('.breaking-container').slideToggle(250);
    }
  });
  //Adds padding for variable height notifications at the teop of the header
var bodypadding = 0;
  if($('.breaking-container').length  > 0){
    console.log($('.breaking-container').outerHeight());
    bodypadding = (16 + parseInt($('.breaking-container').outerHeight()));
    $('body').css('padding-top', bodypadding + 'px' );
  }
  if($('.hasSecondNav').length  > 0){
    console.log($('.secondary-feature-nav').outerHeight());
    bodypadding += (16 + parseInt($('.secondary-feature-nav').outerHeight()));
    $('body').css('padding-top', bodypadding + 'px' );
  }


  $('.header-login').click(function(){
    $(this).toggleClass('active');
  })

  $('.header-search-icon > button').click(function(){
    $(this).next().toggleClass('expanded');
  });

  /*
   * Paragraph Drop Caps
   */
   if ( $('p.has-drop-cap').length > 0 ) {
     $('p.has-drop-cap').each(function(){
       dropCap($(this));
     });
   }

   /*
    * Pullquote Wrapping
    */
    if ( $('.wp-block-pullquote').length > 0 ) {
      $('.wp-block-pullquote blockquote > *:not(cite)').wrapInner("<span class='textbg-fix'></span>");
    }

   /*
    * Inline Gallery
    */
    // if ( $('.wp-block-gallery').length > 0 ) {
    //   $('.wp-block-gallery').slick({
    //     arrows: false,
    //     dots: true,
    //     adaptiveHeight: true,
    //     useCSS: true,
    //     cssEase: 'cubic-bezier(.67,.16,.4,.8)'
    //   })
    // }
    if( $('.northforker-gallery').length > 0 ){
      $('.northforker-gallery').each(function(x, elem){
        $(elem).find('.northforker-gallery-images').slick({
          prevArrow: $(elem).find('.northforker-gallery-nav .mag-prev'),
          nextArrow: $(elem).find('.northforker-gallery-nav .mag-next'),
          dots: false,
          adaptiveHeight: true,
        });
        $(elem).on('beforeChange', function(event, slick, currentSlide, nextSlide){
          console.log(nextSlide);
          $(elem).find(".northforker-gallery-captions span").hide();
          $(elem).find(".northforker-gallery-captions span[slideKey='" + nextSlide +"']").show();
          $(elem).find(".northforker-gallery-progress span").html(++nextSlide);
        });
    
        $(elem).find('.northforker-gallery-images').slick('setPosition');
    
      });
    } 
   






    /*
     * Scrolly
     */
     $(window).scroll(function(){
       if($(window).scrollTop() > 0){
         $('body').addClass('scrolled');
         $('.breaking-container').slideUp(250);
       }else{
         $('body').removeClass('scrolled');
         $('.breaking-container').slideDown(250);
       }
     });


      //Ajax scripts
      $(".loadMore").on('click',function(){
        var args = JSON.parse($(this).attr('data-args'));
        var size = $(this).attr('data-size');
        var container = $(this).attr('data-container');
        var offset = $("."+container).find('article').length;
        var notIn = $(this).attr('post__not_in').split(',').map(function(item) {
            return parseInt(item, 10);
        });
        if ( $(this).data('beginoffset') == 0 ) {
          $(this).data('beginoffset', -1);
          offset = 0;
        }
        console.log(offset + "IS THS OFFSET");

        $(this).addClass('loading');
        var thisref = $(this);

        $.ajax({
            url: ajaxurl,
            data: {
                action: 'do_ajax',
                fn: 'loadMore',
                args: args,
                size: size,
                offset: offset,
                notIn: notIn
            },
            success: function(data){
              if(data !== null){
                console.log(data);
                if (!$.trim(data)){
                  thisref.addClass('complete');
                  thisref.html('<h4>No more posts to show.</h4>')
                } else {
                  $("."+container).append(data);
                }
              }
            },
            complete:function(data){
              thisref.removeClass('loading');
            },
            error: function(errorThrown) {
              console.log(errorThrown);
              thisref.addClass('error');
              thisref.html('<h4>There was an error loading more posts.</h4>')
            }
        });
      }).on('keypress', function(){
        $(this).trigger('click');
      });

      /*
       * Slideshow
       */
      if( $('.slideshow-container').length > 0 ){
        $('.slideshow-container').each(function(x, elem){
          $(elem).find('.slideshow-slides-cmyk').slick({
            prevArrow: $(elem).find('.gallery-nav .mag-prev'),
            nextArrow: $(elem).find('.gallery-nav .mag-next'),
            dots: false,
            adaptiveHeight: true,
            useCSS: true,
            cssEase: 'cubic-bezier(.67,.16,.4,.8)'
          });
          $(elem).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            console.log(nextSlide);
            refreshAds(slots['slideshow_banner_ad']);
            refreshAds(slots['slideshow_square_ad']);

            $(elem).find(".gallery-captions-container span").hide();
            $(elem).find(".gallery-captions-container span[slideKey='" + nextSlide +"']").css('display', 'block');
            $(elem).find(".gallery-progress span").html(++nextSlide);
          });

        });
      }

      if($('.gallery-open, .gallery-first-image').length > 0 ){
        console.log("adding Gallery");
        $('.gallery-open, .gallery-first-image').click(function() {
          $('.gallery-popup-container').addClass('active');
          $('body').toggleClass('open-gallery');
        });
        $('.gallery-popup-header a').click(function(e){
          e.preventDefault();
          $('.gallery-popup-container').removeClass('active');
          $('body').removeClass('open-gallery');
        })

      }

      // newsletter signup
      if ( $('form.mc-embedded-subscribe-form').length ) {
        $('form.mc-embedded-subscribe-form').submit(function(event){
          event.preventDefault();
          register($(this));
        });
      }

      if ( $('.subscribe-sticky').length ) {
        document.addEventListener("PelcroUserLogin", function (e) {
          console.log("PelcroUserLogin");
          setCookie('userloggedin', 'true', 30);
          $('.subscribe-sticky').addClass('closed');
        });

        document.addEventListener("PelcroUserLogout", function (e) {
          console.log("PelcroUserLogout");
          setCookie('userloggedin', 'false', 30);
        });

        setTimeout(function(){
          if ( !$('#pelcro-view-menu').length ) {
            $('.subscribe-sticky').removeClass('closed');
          } else {
            $('.subscribe-sticky').addClass('closed');
          }
        }, 3000);

        $('.subscribe-sticky__close').click(function(){
          $('.subscribe-sticky').addClass('closed');
          setCookie('subscribedismissed', 'true', 30);
        }).keypress(function(){
          $(this).trigger('click');
        });
      }

});

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}



// newsletter signup
function register($form) {
  $.ajax({
    type: $form.attr('method'),
    url: $form.attr('action'),
    data: $form.serialize(),
    cache: false,
    dataType: 'json',
    contentType: "application/json; charset=utf-8",
    error: function(err) {
      alert("Could not connect to the registration server. Please try again later.");
    },
    success: function(data) {
      console.log(data);
      if (data.result != "success") {
        $form.find('.err-msg').html(data.msg);
      } else {
        $form.addClass('success');
        $form.find('.err-msg').addClass('success').html(data.msg);
      }
    }
  });
}


//DFP Scripts
function check_for_ads_in_view(){
	//console.log("checking for ads");
  var started = false;
  googletag.cmd.push(function() {
  if($('.dfp_block').length > 0){

		$('.dfp_block').each(function(){
// 			if($(window).scrollTop() + $(window).height() + 100 > $(this).offset().top && !$(this).hasClass('rendered') && $(window).scrollTop() - 300 < $(this).offset().top && !autoscroll){
					//console.log("getting The Ad");
        //if(started == false){
            started = true;
        var adData = $(this).data();
        var adId = adData.label;
        get_dfp_by_tag(adId);
				$(this).addClass('rendered');
      //}

			//}
		});


	}
});
}


function refreshAds(refresh_slots) {
    if(typeof(refresh_slots) == "undefined") {
        googletag.pubads().refresh();
    } else {
      console.log(refresh_slots);
        googletag.pubads().refresh(refresh_slots);
    }
};


  var slots = {};
function get_dfp_by_tag(tag){
	if($('#'+tag).length<1){
	$('#dfp_block_'+tag).append("<div id='"+tag+"'></div>");


	 googletag.display(tag);
	console.log('getting Ads ' + tag);
	console.log(googletag);
	}
var adSlug;
var adPagetype;
var adNewspaper;
var adCats;
  //look through all PHP written Blocks
console.log($('.dfp_block'));
  googletag.cmd.push(function() {
    $('.dfp_block').each(function(){
      var newspapers = ["notOne","timesreview","north-shore-sun","riverhead-news","suffolk-times","shelter-island-reporter","notone2","northforker"]
      var adData = $(this).data();
       adSizess = adData.size.split('x');
      if(adData.cats.split(",").length == 1){
       adCats = adData.cats;
      }else{
         adCats = adData.cats.split(",");
      }
      var adSize = adData.bannertype;
       adSlug = adData.slug;
       adPagetype = adData.pagetype;
       adNewspaper = newspapers[adData.newspaper];
      var adLabel = adData.label;
      var mapping;
      console.log(adSizess[1] + "ADSIZE");
      slots[adLabel] = googletag.defineSlot('/4848013/'+adData.label, [[parseInt(adSizess[0]),parseInt(adSizess[1])]], adLabel).addService(googletag.pubads());
switch (adSize) {
  case 'topBanner':
   mapping = googletag.sizeMapping().
addSize([1050, 200], [970, 250]).
addSize([728, 300], [728, 90]).
addSize([0, 0], [320, 50]).
//addSize([0, 0], [88, 31]).
// Fits browsers of any size smaller than 640 x 480
build();

    break;
    case 'normalBanner':
     mapping = googletag.sizeMapping().
  addSize([1050, 200], [970, 90]).
  addSize([728, 300], [728, 90]).
  addSize([0, 0], [300, 250]).
  //addSize([0, 0], [88, 31]).
  // Fits browsers of any size smaller than 640 x 480
  build();

    break;
    case 'sidebarBox':
     mapping = googletag.sizeMapping().
  addSize([1024, 768], [300, 250]).
  build();

    break;
    case 'sidebarSkyscraper':
     mapping = googletag.sizeMapping().
  addSize([768, 500], [300, 600]).
  addSize([500, 500], [300, 250]).
  //addSize([0, 0], [88, 31]).
  // Fits browsers of any size smaller than 640 x 480
  build();

    break;
    case 'inlineDesktopSwitch':
     mapping = googletag.sizeMapping().
  addSize([768, 500], [400, 225]).
  addSize([0, 0], [300, 250]).
  //addSize([0, 0], [88, 31]).
  // Fits browsers of any size smaller than 640 x 480
  build();

    break;
    case 'inlineDesktop':
     mapping = googletag.sizeMapping().
    addSize([760, 400], [400, 225]).
    addSize([0, 0], []).
  //addSize([0, 0], [88, 31]).
  // Fits browsers of any size smaller than 640 x 480
  build();

    break;
    case 'inlineMobile':
     mapping = googletag.sizeMapping().
 addSize([320, 700], [300, 250]). // Tablet
 addSize([760, 200], []). // Desktop
 addSize([0, 0], []).
build();

    break;
  default:

}

slots[adLabel].setTargeting('Newspapers', adNewspaper);
slots[adLabel].setTargeting('categories', adCats);
slots[adLabel].setTargeting('slug', adSlug);
slots[adLabel].setTargeting('pagetype', adPagetype);
slots[adLabel].defineSizeMapping(mapping);

console.log(slots[adLabel]);
    });

//Create incontent
var mobileAdSizes = ["inline_mobile_article_1","inline_mobile_article_2","inline_mobile_article_3","inline_mobile_article_4","inline_mobile_article_5","inline_mobile_article_6","inline_mobile_article_7","inline_mobile_article_8","inline_mobile_article_9"];
var desktopAdSizes = ["inline_desktop_article_1","inline_desktop_article_2","inline_desktop_article_3","inline_desktop_article_4","inline_desktop_article_5","inline_desktop_article_6","inline_desktop_article_7","inline_desktop_article_8","inline_desktop_article_9"];

if($(".single-body").length != 0){
  var tags = $(".single-body").children();
  var adCount = 0;
  var currentAdCount = 0;
  var maxChildCount = 13;
  var maxMobileChildCount = 4;
  var maxMobileAds = 9;
  var maxDesktopAds = 4;

  if(adSlug = 'coronavirus-on-north-fork-live-updates-from-times-review-media-group-2'){
    var maxChildCount = 8;
    var maxMobileChildCount = 5;
    var maxMobileAds = 9;
    var maxDesktopAds = 8;
  }
  //check if the article is long.. if so spread the ads farther out.. based on the count.
  if(tags.length > maxMobileAds*maxMobileChildCount){
    maxMobileChildCount = Math.floor(tags.length/maxMobileAds);
    console.log("spreading Ads");
    console.log(maxMobileChildCount);
  }
  if(tags.length > maxDesktopAds*maxChildCount){
    maxChildCount = Math.floor(tags.length/maxDesktopAds);
    console.log("spreading Ads");
    console.log(maxChildCount);
  }

console.log("has above tags");
console.log(adNewspaper);
console.log(adCats);
console.log(adSlug);
var pageIsSponsored = (adCats.indexOf("sponsored-content") > -1);
console.log(pageIsSponsored);
console.log(adPagetype);
console.log($(".single-body").children());
console.log(tags);


if ( !$('body').hasClass('post-template-template-special-content') ) {
  $.each(tags,function(x,i){
    adCount++;
  if(isMobile === true){
    if(adCount % maxMobileChildCount == 0 && !pageIsSponsored){
      console.log("mobile injection");
  $(i).after('<div class="ad ad-inline mobile"><div id="dfp_block_'+mobileAdSizes[currentAdCount]+'"><div id="'+mobileAdSizes[currentAdCount]+'"></div></div></div>');
  googletag.display(mobileAdSizes[currentAdCount]);

      console.log("adding");
      var mapping;
      slots[mobileAdSizes[currentAdCount]] = googletag.defineSlot('/4848013/'+mobileAdSizes[currentAdCount], [[300, 250]], mobileAdSizes[currentAdCount]).addService(googletag.pubads());
      var mapping = googletag.sizeMapping().
     addSize([0, 0], [300, 250]).
  build();
      slots[mobileAdSizes[currentAdCount]].setTargeting('Newspapers', adNewspaper);
      slots[mobileAdSizes[currentAdCount]].setTargeting('categories', adCats);
      slots[mobileAdSizes[currentAdCount]].setTargeting('slug', adSlug);
      slots[mobileAdSizes[currentAdCount]].setTargeting('pagetype', adPagetype);
      slots[mobileAdSizes[currentAdCount]].defineSizeMapping(mapping);
      console.log(slots[mobileAdSizes[currentAdCount]]);

      currentAdCount++;
  }
  }else{
    if(adCount % maxChildCount == 0 && !pageIsSponsored){
      console.log("desktop injection");
  $(i).after('<div class="ad ad-inline desktop"><div id="dfp_block_'+desktopAdSizes[currentAdCount]+'"><div id="'+desktopAdSizes[currentAdCount]+'"></div></div></div>');
  googletag.display(desktopAdSizes[currentAdCount]);

      console.log("adding");
      var mapping;
      slots[desktopAdSizes[currentAdCount]] = googletag.defineSlot('/4848013/'+desktopAdSizes[currentAdCount], [[400, 225]], desktopAdSizes[currentAdCount]).addService(googletag.pubads());
      var mapping = googletag.sizeMapping().
     addSize([0, 0], []).
     addSize([768, 400], [400, 225]).
   build();
      slots[desktopAdSizes[currentAdCount]].setTargeting('Newspapers', adNewspaper);
      slots[desktopAdSizes[currentAdCount]].setTargeting('categories', adCats);
      slots[desktopAdSizes[currentAdCount]].setTargeting('slug', adSlug);
      slots[desktopAdSizes[currentAdCount]].setTargeting('pagetype', adPagetype);
      slots[desktopAdSizes[currentAdCount]].defineSizeMapping(mapping);
      console.log(slots[desktopAdSizes[currentAdCount]]);

      currentAdCount++;
  }
  }

  });
}




}

console.log("finished Loading");

console.log(slots);


    googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs(true);
    googletag.pubads().enableLazyLoad({
  fetchMarginPercent: 500,  // Fetch slots within 5 viewports.
  renderMarginPercent: 200,  // Render slots within 2 viewports.
  mobileScaling: 2.0  // Double the above values on mobile.
});
    googletag.enableServices();
  });

}


function dropCap(target) {
  let text = $(target).html();
  text = text.replace(/^((\S+\s+){2}\S+)/, '<span class="lead-text">$1</span>');
  target.html(text);
}
